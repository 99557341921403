var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-tabs",
    {
      attrs: {
        vertical: "",
        "content-class": "col-12 col-md-9 mt-1 mt-md-0",
        pills: "",
        "nav-wrapper-class": "col-md-3 col-12",
        "nav-class": "nav-left"
      }
    },
    [
      _vm.$can("read", "cameras") || _vm.$can("read", "camerasmanagement")
        ? _c(
            "b-tab",
            {
              attrs: { active: "" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "title",
                    fn: function() {
                      return [
                        _c("feather-icon", {
                          staticClass: "mr-50",
                          attrs: { icon: "ActivityIcon", size: "18" }
                        }),
                        _c("span", { staticClass: "font-weight-bold" }, [
                          _vm._v("Current Status")
                        ])
                      ]
                    },
                    proxy: true
                  }
                ],
                null,
                false,
                3798124313
              )
            },
            [
              _vm.heartbeatData
                ? _c(
                    "div",
                    [
                      _c(
                        _vm.cameraData === undefined ? "div" : "b-card",
                        { tag: "component" },
                        [
                          _c(
                            "b-alert",
                            {
                              attrs: {
                                variant: "danger",
                                show: _vm.cameraData === undefined
                              }
                            },
                            [
                              _c("h4", { staticClass: "alert-heading" }, [
                                _vm._v(" Error fetching camera data ")
                              ]),
                              _c("div", { staticClass: "alert-body" }, [
                                _vm._v(
                                  " No camera found with this cameraname. "
                                )
                              ])
                            ]
                          ),
                          _c("camera-edit-tab-status", {
                            attrs: {
                              "camera-data": _vm.cameraData,
                              "heartbeat-data": _vm.heartbeatData
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ]
          )
        : _vm._e(),
      _vm.$can("update", "cameras") || _vm.$can("update", "camerasmanagement")
        ? _c(
            "b-tab",
            {
              attrs: { active: _vm.selectedTabName === "settings" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "title",
                    fn: function() {
                      return [
                        _c("feather-icon", {
                          staticClass: "mr-50",
                          attrs: { icon: "SettingsIcon", size: "18" }
                        }),
                        _c("span", { staticClass: "font-weight-bold" }, [
                          _vm._v("Settings")
                        ])
                      ]
                    },
                    proxy: true
                  }
                ],
                null,
                false,
                3961467945
              )
            },
            [
              _vm.cameraData
                ? _c(
                    "div",
                    [
                      _c(
                        _vm.cameraData === undefined ? "div" : "b-card",
                        { tag: "component" },
                        [
                          _c("camera-edit-tab-general", {
                            attrs: { "camera-data": _vm.cameraData },
                            on: { goToTab: _vm.goToTab }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ]
          )
        : _vm._e(),
      (_vm.$can("update", "cameras") ||
        _vm.$can("update", "camerasmanagement")) &&
      _vm.cameraData &&
      _vm.cameraData.is_active
        ? _c(
            "b-tab",
            {
              scopedSlots: _vm._u(
                [
                  {
                    key: "title",
                    fn: function() {
                      return [
                        _c("feather-icon", {
                          staticClass: "mr-50",
                          attrs: { icon: "ClockIcon", size: "18" }
                        }),
                        _c("span", { staticClass: "font-weight-bold" }, [
                          _vm._v("Scheduler")
                        ])
                      ]
                    },
                    proxy: true
                  }
                ],
                null,
                false,
                1788918934
              )
            },
            [
              _vm.users
                ? _c(
                    "div",
                    [
                      _c(
                        _vm.cameraData === undefined || _vm.users === undefined
                          ? "div"
                          : "b-card",
                        { tag: "component" },
                        [
                          _c("camera-edit-tab-scheduler", {
                            attrs: {
                              "camera-data": _vm.cameraData,
                              users: _vm.users
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ]
          )
        : _vm._e(),
      (_vm.$can("update", "cameras") ||
        _vm.$can("update", "camerasmanagement")) &&
      _vm.cameraData &&
      _vm.cameraData.is_active
        ? _c(
            "b-tab",
            {
              scopedSlots: _vm._u(
                [
                  {
                    key: "title",
                    fn: function() {
                      return [
                        _c("feather-icon", {
                          staticClass: "mr-50",
                          attrs: { icon: "AlertTriangleIcon", size: "18" }
                        }),
                        _c("span", { staticClass: "font-weight-bold" }, [
                          _vm._v("Notifications")
                        ])
                      ]
                    },
                    proxy: true
                  }
                ],
                null,
                false,
                974786423
              )
            },
            [
              _vm.users
                ? _c(
                    "div",
                    [
                      _c(
                        _vm.cameraData === undefined || _vm.users === undefined
                          ? "div"
                          : "b-card",
                        { tag: "component" },
                        [
                          _c("camera-edit-tab-notifications", {
                            attrs: {
                              "camera-data": _vm.cameraData,
                              users: _vm.users
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ]
          )
        : _vm._e(),
      (_vm.$can("update", "cameras") ||
        _vm.$can("update", "camerasmanagement")) &&
      _vm.cameraData &&
      _vm.cameraData.is_active
        ? _c(
            "b-tab",
            {
              scopedSlots: _vm._u(
                [
                  {
                    key: "title",
                    fn: function() {
                      return [
                        _c("feather-icon", {
                          staticClass: "mr-50",
                          attrs: { icon: "LockIcon", size: "18" }
                        }),
                        _c("span", { staticClass: "font-weight-bold" }, [
                          _vm._v("Permissions")
                        ])
                      ]
                    },
                    proxy: true
                  }
                ],
                null,
                false,
                163464314
              )
            },
            [
              _vm.users
                ? _c(
                    "div",
                    [
                      _c(
                        _vm.cameraData === undefined || _vm.users === undefined
                          ? "div"
                          : "b-card",
                        { tag: "component" },
                        [
                          _c("camera-edit-tab-permissions", {
                            attrs: {
                              "camera-data": _vm.cameraData,
                              users: _vm.users
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ]
          )
        : _vm._e(),
      _vm.$can("read", "camerasmanagement")
        ? _c(
            "b-tab",
            {
              scopedSlots: _vm._u(
                [
                  {
                    key: "title",
                    fn: function() {
                      return [
                        _c("feather-icon", {
                          staticClass: "mr-50",
                          attrs: { icon: "DatabaseIcon", size: "18" }
                        }),
                        _c("span", { staticClass: "font-weight-bold" }, [
                          _vm._v("Logs")
                        ])
                      ]
                    },
                    proxy: true
                  }
                ],
                null,
                false,
                1847021371
              )
            },
            [
              _vm.users
                ? _c(
                    "div",
                    [
                      _c(
                        _vm.cameraData === undefined || _vm.users === undefined
                          ? "div"
                          : "b-card",
                        { tag: "component" },
                        [
                          _c("camera-edit-tab-logs", {
                            attrs: {
                              "camera-data": _vm.cameraData,
                              users: _vm.users
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ]
          )
        : _vm._e(),
      _vm.$can("update", "camerasmanagement") &&
      (_vm.userData.group === "Management" ||
        _vm.userData.group === "EyeTrax_Admin")
        ? _c(
            "b-tab",
            {
              scopedSlots: _vm._u(
                [
                  {
                    key: "title",
                    fn: function() {
                      return [
                        _c("feather-icon", {
                          staticClass: "mr-50",
                          attrs: { icon: "CommandIcon", size: "18" }
                        }),
                        _c("span", { staticClass: "font-weight-bold" }, [
                          _vm._v("Commands")
                        ])
                      ]
                    },
                    proxy: true
                  }
                ],
                null,
                false,
                1767326490
              )
            },
            [
              _vm.cameraData
                ? _c(
                    "div",
                    [
                      _c(
                        _vm.cameraData === undefined || _vm.users === undefined
                          ? "div"
                          : "b-card",
                        { tag: "component" },
                        [
                          _c("camera-edit-tab-commands", {
                            attrs: { "camera-data": _vm.cameraData }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }