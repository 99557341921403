var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.$can("update", "cameras") ||
    _vm.$can("update", "camerasmanagement")
    ? _c(
        "div",
        [
          _c(
            "b-media",
            {
              staticClass: "mb-2",
              scopedSlots: _vm._u(
                [
                  {
                    key: "aside",
                    fn: function() {
                      return undefined
                    },
                    proxy: true
                  }
                ],
                null,
                false,
                647001971
              )
            },
            [
              _c(
                "h4",
                { staticClass: "mb-1" },
                [
                  _c("feather-icon", {
                    staticClass: "mr-50",
                    attrs: { icon: "CameraIcon", size: "22" }
                  }),
                  _vm._v(
                    " Camera Settings -- " +
                      _vm._s(_vm.cameraData.sitename) +
                      ", " +
                      _vm._s(_vm.cameraData.location_name)
                  ),
                  _c("br")
                ],
                1
              ),
              _c("small", [
                _vm._v("Camera: " + _vm._s(_vm.cameraData.unit_number))
              ]),
              _c("br")
            ]
          ),
          _c("validation-observer", {
            ref: "observer",
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function(ref) {
                    var handleSubmit = ref.handleSubmit
                    return [
                      _c(
                        "b-form",
                        {
                          on: {
                            submit: function($event) {
                              $event.preventDefault()
                              return handleSubmit(_vm.validateForm)
                            }
                          }
                        },
                        [
                          _c(
                            "b-row",
                            { staticClass: "mt-2 mb-1 border-bottom-primary" },
                            [
                              _c("b-col", [
                                _c("h4", [_vm._v("Common Commands")])
                              ])
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            { staticClass: "mt-2 mb-4" },
                            [
                              _c(
                                "b-col",
                                { attrs: { md: "3" } },
                                [
                                  _vm.$can("update", "camerasmanagement")
                                    ? _c(
                                        "b-button",
                                        {
                                          staticClass:
                                            "mb-1 mb-sm-0 mr-0 mr-sm-1",
                                          attrs: {
                                            variant: "primary",
                                            block: ""
                                          },
                                          on: { click: _vm.phoneHome }
                                        },
                                        [_vm._v(" Phone Home ")]
                                      )
                                    : _vm._e()
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                { attrs: { md: "3" } },
                                [
                                  _vm.$can("update", "camerasmanagement")
                                    ? _c(
                                        "b-button",
                                        {
                                          staticClass:
                                            "mb-1 mb-sm-0 mr-0 mr-sm-1",
                                          attrs: {
                                            variant: "primary",
                                            block: ""
                                          },
                                          on: { click: _vm.updateServerAddress }
                                        },
                                        [_vm._v(" Update Camera Endpoints ")]
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            { staticClass: "mt-2 mb-1 border-bottom-primary" },
                            [
                              _c("b-col", [
                                _c("h4", [_vm._v("Firmware Update")])
                              ])
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            { staticClass: "mb-1" },
                            [
                              _c("b-col", [
                                _vm._v(
                                  " Current version: " +
                                    _vm._s(
                                      _vm.cameraData.current_status.firmware
                                        .version
                                    )
                                ),
                                _c("br"),
                                _vm._v(
                                  " Updated: " +
                                    _vm._s(
                                      _vm.cameraData.current_status.firmware.updated_on
                                        .replace("T", " ")
                                        .replace("Z", "")
                                    ) +
                                    " "
                                )
                              ])
                            ],
                            1
                          ),
                          _vm.firmwareVersionOptions !== null &&
                          _vm.firmwareVersionOptions.length
                            ? _c(
                                "b-row",
                                [
                                  _c(
                                    "b-col",
                                    { attrs: { md: "6" } },
                                    [
                                      _c(
                                        "b-form-group",
                                        {
                                          attrs: {
                                            label: "Firmware Version",
                                            "label-for": "firmware_version"
                                          }
                                        },
                                        [
                                          _c("validation-provider", {
                                            attrs: {
                                              name: "firmware_version",
                                              rules: "required"
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function(ref) {
                                                    var errors = ref.errors
                                                    return [
                                                      _c("v-select", {
                                                        key:
                                                          _vm.firmwareVersion,
                                                        attrs: {
                                                          options:
                                                            _vm.firmwareVersionOptions,
                                                          reduce: function(
                                                            val
                                                          ) {
                                                            return val.value
                                                          },
                                                          clearable: false,
                                                          "input-id":
                                                            "firmware_version"
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.firmwareVersion,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.firmwareVersion = $$v
                                                          },
                                                          expression:
                                                            "firmwareVersion"
                                                        }
                                                      }),
                                                      _c(
                                                        "small",
                                                        {
                                                          staticClass:
                                                            "text-danger"
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(errors[0])
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.firmwareVersionOptions
                            ? _c(
                                "b-row",
                                { staticClass: "mb-4" },
                                [
                                  _c(
                                    "b-col",
                                    { attrs: { md: "3" } },
                                    [
                                      _vm.$can("update", "camerasmanagement")
                                        ? _c(
                                            "b-button",
                                            {
                                              staticClass:
                                                "mb-1 mb-sm-0 mr-0 mr-sm-1",
                                              attrs: {
                                                variant: "primary",
                                                block: ""
                                              },
                                              on: { click: _vm.updateFirmware }
                                            },
                                            [_vm._v(" Update Firmware ")]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "b-row",
                            { staticClass: "mt-2 mb-1 border-bottom-primary" },
                            [
                              _c("b-col", [
                                _c("h4", [_vm._v("Send Manual Command")])
                              ])
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                { attrs: { md: "12" } },
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label: "Manual Command",
                                        "label-for": "manual_command"
                                      }
                                    },
                                    [
                                      _c("validation-provider", {
                                        attrs: { name: "manual_command" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function(ref) {
                                                var errors = ref.errors
                                                return [
                                                  _c("b-form-textarea", {
                                                    attrs: {
                                                      id: "manual_command",
                                                      rows: "3",
                                                      "max-rows": "6"
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.currentManualCommand,
                                                      callback: function($$v) {
                                                        _vm.currentManualCommand = $$v
                                                      },
                                                      expression:
                                                        "currentManualCommand"
                                                    }
                                                  }),
                                                  _c(
                                                    "small",
                                                    {
                                                      staticClass: "text-danger"
                                                    },
                                                    [_vm._v(_vm._s(errors[0]))]
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            { staticClass: "mb-2" },
                            [
                              _c(
                                "b-col",
                                { attrs: { md: "3" } },
                                [
                                  _vm.$can("update", "camerasmanagement")
                                    ? _c(
                                        "b-button",
                                        {
                                          staticClass:
                                            "mb-1 mb-sm-0 mr-0 mr-sm-1",
                                          attrs: {
                                            variant: "primary",
                                            block: ""
                                          },
                                          on: { click: _vm.sendManualCommand }
                                        },
                                        [_vm._v(" Send Manual Command ")]
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  }
                }
              ],
              null,
              false,
              3731453403
            )
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }